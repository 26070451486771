<template>
  <div class="text-center">
    
  </div>
</template>

<script>
export default {
  name: "addChannelWordpress",
  data() {
    return {
      channel: {},
    };
  },
  methods: {
  created() {
    this.$http
      .get(`/wordpress/channel/add/back/low`, {
        params: {
          code: this.$route.query.code,
          state: this.$route.query.state,
          scope: this.$route.query.scope,
        },
      })
      .then((response) => {
        if(response){
          console.log(response);
          this.channel = response.data;
          this.getChannelData();
          this.showLoading = false;
        }else{
          this.showLoading = true;
        }

        if (response.status == 500) {
            this.showError = true;
        }

      }).catch((error) => {
        console.log(error);
          if (error.response.status == 500) {
            this.showError = true;
          }

          if (error.response.status !== 401) {
            this.$router.push("/channels").catch(() => { });
          }
      });
  },
  }
};
</script>